










































































































































































	.layoutBox {
	}
.communityNew {
	background-color: #F7F7FA;
	h3 {
		font-size: 0.32rem;
		// font-family: PingFang SC;
		font-weight: bold;
		color: #373737;
		margin-bottom: .25rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.more {
			width: .6rem;
			text-align: center;
		}
	}

	.bottomColStyleP {
		font-size: 0.24rem;
		margin-top: 0.2rem;
	}

	.bottomSpanStyles {
		/* text-align: center; */
		color: #999999;
		font-size: 0.3rem;
	}

	.styleBottomVol {
		padding-bottom: 0.45rem;
	}

	.bodydiv1Col2Style {
		color: #878787;
		margin-top: 0.05rem;
	}

	.bottom {
		margin-top: 0.2rem;
		background: #ffffff;
		padding: 0 0.2rem;
		margin-bottom: 0.2rem;
		border-top-left-radius: 0.32rem;
		border-top-right-radius: 0.32rem;
		// padding-top: 0.4rem;
		.top {
			border-bottom: 0.01rem solid #D3D9DE;
			padding: 0.2rem 0;
			display: flex;
			align-items: center;
			.kuai {
				margin-right: 0.22rem;
				width: 5px;
				height: 0.26rem;
				background-color: #3965FC;
			}
			h3 {
				margin-bottom: 0;
			}
		}
	}

	.van3Style {
		// margin-top: 0.35rem;
		padding: 0.4rem 0;
	}

	.van4Style {
		margin-top: 0.35rem;
		text-align: center;
		border-bottom: 1px solid #f5f5f5;
		padding-bottom: 0.3rem;
	}

	.row3Style {
		margin-bottom: 0.5rem;

		span,
		p {
			text-align: center;
			// height: 0.48rem;
			line-height: 0.48rem;
			display: block;
			font-size: 0.24rem;
			// font-family: PingFang SC;
		}
		span {
			font-size: .4rem;
			font-weight: 500;
			height: 0.48rem;
		}
		
		&:nth-last-child(2),
		&:nth-last-child(1) {
			margin-bottom: 0;
		}
	}

	.row3Style.color1 {
		span,
		p {
			border-top: 0.03rem solid #ff4b04;
		}
	}

	.row3Style.color2 {
		span,
		p {
			border-top: 0.03rem solid #ff9e04;
		}
	}

	.bodyDiv1 {
		margin-top: 0.2rem;
		background: #ffffff;
		padding: 0 0.2rem;
		// margin-bottom: 0.2rem;
		border-top-left-radius: 0.32rem;
		border-top-right-radius: 0.32rem;
		// padding-top: 0.4rem;
		.top {
			border-bottom: 0.01rem solid #D3D9DE;
			padding: 0.2rem 0;
			display: flex;
			align-items: center;
			.kuai {
				margin-right: 0.22rem;
				width: 5px;
				height: 0.26rem;
				background-color: #3965FC;
			}
			h3 {
				margin-bottom: 0;
			}
		}
	}

	.content {
		background: #ffffff;
		padding: 0 0.2rem;
		border-top-left-radius: 0.32rem;
		border-top-right-radius: 0.32rem;
		.top {
			border-bottom: 0.01rem solid #D3D9DE;
			padding: 0.2rem 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.t_left {
				display: flex;
				align-items: center;
				.kuai {
					margin-right: 0.22rem;
					width: 5px;
					height: 0.26rem;
					background-color: #3965FC;
				}
			}
			h3 {
				margin-bottom: 0;
			}
			.t_right {
				margin-right: 0.2rem;
				color: #999999;
				font-size: 0.24rem;
				.icon_arrR {
					margin-left: 0.1rem;
					width: .2rem;
					height: .2rem;
					border-top: .04rem solid #999;
					border-right: .04rem solid #999;
				}
			}
		}

		.content-member {
			padding: 0.4rem 0;
			text-align: center;
			.zhiTitle {
				font-size: 0.24rem;
			}

			.zhiCent {
				font-size: 0.24rem;
				margin-bottom: 0.15rem;
				margin-top: 0.15rem;
				color: #666666;
				display: flex;
				align-items: center;
				.state img {
					width: 0.24rem;
					display: block;
					margin: 0 auto;
				}
				.active {
					color: #2E62FF;
				}
				.unactive {
					color: #6F7989;
				}
			}
			
			.left {
				// text-align: left;
			}
		}
	}
}

// ----------20200410[start]---------

.commonHead1 {
	background-image: url(../../assets/imgs/my_team.png);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: 100% 100%;
	padding: 0 0.3rem;
	.head {
		position: relative;
		height: 0.88rem;
		line-height: 0.88rem;
	}
	.left {
		position: absolute;
		left: 0;
		top: 0;
		.icon {
			width: 0.6rem;
			height: 0.88rem;
			display: flex;
			justify-content: center;
			align-items: center;
			.icon_arrL {
				border-top: 0.04rem solid #ffffff;
				border-left: 0.04rem solid #ffffff;
			}
			img {
				width: 0.4rem;
				height: 0.4rem;
			}
		}
	}
	.title {
		color: #ffffff;
		font-size: 0.32rem;
		text-align: center;
		text-transform: capitalize;
	}
}

.communityInfo {
	background-image: url(../../assets/imgs/my_team.png);
	background-repeat: no-repeat;
	background-size: 100% 2.6rem;
	margin-top: -0.88rem;
	height: 2.6rem;
	.list {
		padding-top: 1.28rem;
		margin: 0 1.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.item {
			text-align: center;
			.yen {
				font-size: 0.36rem;
				color: #FFFFFF;
			}
			p {
				margin-top: 0.08rem;
				color: #FFFFFF;
				font-size: 0.24rem;
			}
		}
		.item1 {
			text-align: center;
			.yen {
				font-size: 0.36rem;
				color: #FFFFFF;
			}
			p {
				margin-top: 0.08rem;
				color: #FFFFFF;
				font-size: 0.24rem;
			}
		}
	}
}
// ----------20200410[end]---------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;